import { Routes } from '@angular/router';
import { LoginRoutePath, LogoutRoutePath, ModeratorSessionsRoutePath, NotFoundRoutePath } from '@shared';
import { PortalAppRedirectComponent } from './portalApp.Redirect';

export const MainAppRoutesModerator: Routes = [
    {
        path: LoginRoutePath,
        loadChildren: () => import(/* webpackChunkName: 'login.module' */'src/app/pages/loginModerator/loginModerator.Module').then(m => m.LoginModeratorModule),
    },
    {
        path: LogoutRoutePath,
        loadChildren: () => import(/* webpackChunkName: 'logout.module' */'src/app/pages/logout/logout.Module').then(m => m.LogoutModule),
    },
    {
        path: `:eventCode/${ModeratorSessionsRoutePath}`,
        loadChildren: () => import(/* webpackChunkName: 'sessions.module' */'src/app/pages/sessions/sessions.Module').then(m => m.SessionsModule),
    },
    {
        path: NotFoundRoutePath,
        loadChildren: () => import(/* webpackChunkName: 'notFound.module' */'src/app/pages/notFound/notFound.Module').then(m => m.NotFoundModule),
    },
    {
        path: '**',
        component: PortalAppRedirectComponent
    }
];
