// external modules
import { APP_BASE_HREF } from '@angular/common';
import { Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { Config, CORE_CONFIGURATION, CoreModule, provideLazyRoutes, ROUTES_LEVEL, setReadOnlyEntityProdMode } from 'ngx-myia-core';
import { CoreUIModule } from 'ngx-myia-core-ui';
import { DialogModule } from 'ngx-myia-dialog';
import { ITOKEN_SERVICE } from 'ngx-myia-http';
import { LOCALIZATION_CONFIGURATION, LocalizationModule, POLoaderFactory, TranslatePOLoader } from 'ngx-myia-localization';
import { REDUX_MODULE_CONFIG, ReduxModule } from 'ngx-myia-redux';
import { SIGNAL_R_CONFIGURATION, SignalRModule } from 'ngx-myia-signal-r';
import { SplashModule } from 'ngx-myia-splash';
import { ToastModule } from 'ngx-myia-toast';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AppMode, TokenService, ViewSharedComponentsModule } from '@shared';
import { Observable, Observer } from 'rxjs';
import { PortalAppComponent } from './portalApp.Component';
import { PortalAppRedirectComponent } from './portalApp.Redirect';
import { MainAppRoutes } from './portalApp.Routes';
import { MainAppRoutesModerator } from './portalApp.Routes.Moderator';
// Myia portal styles
import './styles/index.scss';

function requireAll(r: any) {
    r.keys().forEach(r);
}

requireAll(require['context']('./icons/svg/', true, /\.svg$/));

/* Redux configuration - must be provided as factory to be AOT compatible */
export function provideReducers(): Array<any> {
    return [];
}

export function providePersistedReducersNames(): Array<string> {
    return [];
}

export function ReduxConfigFactory() {
    return {
        reduxStorageKey: Config.get<string>('reduxStorageKey'),
        useLogger: !Config.isProd
    };
}

/* Localization configuration */
export function LocalizationConfigFactory() {
    const cultures = Config.get<string>('cultures');
    return {
        defaultCulture: Config.get<string>('defaultCulture'),
        supportedCultures: cultures ? cultures.split('|') : null,
        translateLoaderFactory: TranslateLoaderFactory
    };
}

/* SignalR configuration */
export function SignalRConfigFactory() {
    return {
        signalRUrl: Config.get<string>('BASE_API_URL')
    };
}

export function TranslateLoaderFactory() {
    return new TranslatePOLoader((lang: string) => {
        return new Observable((observer: Observer<any>) => {
            import(`./locale/${lang}/translations.po`).then(translations => {
                observer.next({...translations});
                observer.complete();
            });
        });
    });
}

const pwaEnabled = !!process.env['MYIA_CONFIG']['PWA']; // dont user Config.get() ... its not initialized yet
const appMode = AppMode[process.env['MYIA_CONFIG']['appMode'] as string || 'myiaMy']; // dont user Config.get() ... its not initialized yet
const appRoutes = appMode === AppMode.myiaModerator ? MainAppRoutesModerator : MainAppRoutes;

if (Config.isProd) {
    setReadOnlyEntityProdMode();
}


@NgModule({
    declarations: [
        PortalAppComponent,
        PortalAppRedirectComponent
    ],
    imports: [
        // animations
        BrowserAnimationsModule,
        BrowserModule, //  CommonModule,
        // Core config
        CoreModule.forRoot({
            clientConfigKey: 'MYIA_CONFIG'
        }),
        CoreUIModule.forRoot(),
        DialogModule,
        // Toast module
        ToastModule.forRoot({timeOut: 3000, cssClass: 'toastMessage'}),
        // 3rd party translation module
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: POLoaderFactory,
                deps: [Injector]
            }
        }),

        // Localization module
        LocalizationModule.forRoot({
            config: {
                provide: LOCALIZATION_CONFIGURATION,
                useFactory: LocalizationConfigFactory
            }
        }),
        // Redux module
        ReduxModule.forRoot(provideReducers, providePersistedReducersNames, {
            config: {
                provide: REDUX_MODULE_CONFIG,
                useFactory: ReduxConfigFactory,
                deps: [
                    CORE_CONFIGURATION // !important - redux config depends on core config initialization
                ]
            }
        }),
        // Splash module
        SplashModule.forRoot({
            splashSelector: 'splash'
        }),
        // PWA support
        ServiceWorkerModule.register('/service-worker.js', {
            enabled: pwaEnabled
        }),
        // SignalR module
        SignalRModule.forRoot({
            config: {
                provide: SIGNAL_R_CONFIGURATION,
                useFactory: SignalRConfigFactory
            }
        }),
        // Toast module
        ToastModule.forRoot({timeOut: 3000, cssClass: 'toastMessage'}),

        ViewSharedComponentsModule,
        // Router
        RouterModule.forRoot([], {
            enableTracing: true,
            relativeLinkResolution: 'corrected',
            useHash: false,
            paramsInheritanceStrategy: 'always' // inherit route parameters to child routes (e.g. eventId)
        }),
    ],
    bootstrap: [PortalAppComponent],
    providers: [
        // {provide: AuthConfig, useFactory: () => { return new AuthConfig(); }},

        {provide: APP_BASE_HREF, useValue: ''},

        // providers used by http module
        {provide: ITOKEN_SERVICE, useClass: TokenService},
        {provide: TokenService, useExisting: ITOKEN_SERVICE},

        {
            provide: ROUTES_LEVEL,
            useValue: appRoutes
        },

        ...provideLazyRoutes(appRoutes, 'portal')
    ]
})
export class PortalAppModule {
}
