import './polyfills';

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { PortalAppModule } from './app';
// depending on the env mode, enable prod mode or add debugging modules
if (process.env.ISPROD) {
    enableProdMode();
}
platformBrowserDynamic().bootstrapModule(PortalAppModule).then(ref => {
    // Ensure Angular destroys itself on hot reloads.
    if (window['ngRef']) {
        window['ngRef'].destroy();
    }
    window['ngRef'] = ref;

    // Otherwise, log the boot error
}).catch(err => console.error(err));
