import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Config } from 'ngx-myia-core';
import { CultureService } from 'ngx-myia-localization';
import { AppMode, DashboardRoutePath } from '@shared';
import { ModeratorSessionsRoutePath, NotFoundRoutePath } from './pages/shared/routes.paths';

@Component({
    template: ''
})
export class PortalAppRedirectComponent {
    constructor(activatedRoute: ActivatedRoute, cultureService: CultureService, router: Router) {
        const appMode = AppMode[Config.get<string>('appMode') || 'myiaMy'];
        activatedRoute.queryParams.subscribe(queryParams => {
            //https://stackoverflow.com/questions/36687164/pass-parameters-in-angular2-routeconfig-redirectto
            const cultureQS = queryParams['culture'];
            if (cultureQS) {
                cultureService.use(cultureQS).subscribe();
            }
            if (appMode === AppMode.myiaModerator) {
                // redirect to not found page
                router.navigate([`/${ModeratorSessionsRoutePath}`]);
            } else {
                // redirect to dashboard
                router.navigate([`/${DashboardRoutePath}`]);
            }
        });
    }

}
